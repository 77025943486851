<template>
<div class="table-content">
        <div class="table-options">
            <div class="delete" v-if="selected.length > 0">
                <vs-icon icon="delete"></vs-icon>
            </div>
        </div>
        <div class="table-wrapper with-border">
            <vs-table :data="bookings">
                <template slot="thead">
                    <vs-th sort-key="Booking_Last_Service_Date">Last Service Date</vs-th>
                    <vs-th sort-key="Travel_Date">Travel Date</vs-th>
                    <!-- <vs-th sort-key="Booking_Name">Booking Name</vs-th> -->
                    <vs-th sort-key="Booking_Reference">Reference</vs-th>
                    <vs-th sort-key="Agent_Code">Agency</vs-th>
                    <vs-th sort-key="Booking_Consultant">Agent</vs-th>
                    <vs-th sort-key="Booking_Status">Status</vs-th>
                    <vs-th sort-key="Original_Costs_Inc">Costo</vs-th>
                    <vs-th sort-key="Expected_Sales_Inc"> Bkg Value</vs-th>
                    <vs-th sort-key="Actual_Sales_Inc">Invoiced</vs-th>
                    <vs-th sort-key="RETAILREAL">Diff Bkg-Inv </vs-th>
                    <vs-th sort-key="Booking_Receipt_Inc">Receipt </vs-th>
                    <vs-th sort-key="MARGIN">Saldo </vs-th>
                    <vs-th class="center"></vs-th>
                </template>
                <template slot-scope="{ data }">
                    <template :data="tr"  v-for="(tr, indextr) in data">
                        <vs-tr :key="indextr" :class="{'active': opened.includes(data[indextr].id)}">
                            <vs-td :data="data[indextr].Booking_Last_Service_Date"> 
                                {{ data[indextr].Booking_Last_Service_Date | moment('timezone', 'Etc/GMT', 'll')}}
                            </vs-td>
                            <vs-td :data="data[indextr].Travel_Date"> 
                                {{ data[indextr].Travel_Date | moment('timezone', 'Etc/GMT', 'll')}} 
                            </vs-td>
                            <vs-td :data="data[indextr].Booking_Reference"> 
                                {{ data[indextr].Booking_Reference }}
                            </vs-td>
                            <vs-td :data="data[indextr].Agent_Code">
                                 {{ data[indextr].Agent_Code }} 
                            </vs-td>
                            <vs-td :data="data[indextr].Booking_Consultant"> 
                                {{ data[indextr].Booking_Consultant }} 
                            </vs-td>
                            <vs-td :data="data[indextr].Booking_Status">
                                 {{ data[indextr].Booking_Status }} 
                            </vs-td>
                            <vs-td :data="data[indextr].Original_Costs_Inc" class="success"> 
                                {{ $n(data[indextr].Original_Costs_Inc, 'currency') }} 
                            </vs-td>
                            <vs-td :data="data[indextr].Expected_Sales_Inc" class="default"> 
                                {{ $n(data[indextr].Expected_Sales_Inc, 'currency') }} 
                            </vs-td>
                            <vs-td :data="data[indextr].Actual_Sales_Inc" class="default"> 
                                {{ $n(data[indextr].Actual_Sales_Inc, 'currency') }} 
                            </vs-td>
                            <vs-td :data="data[indextr].Actual_Sales_Inc" :class="[{'pending': getDiffValues(data[indextr].Expected_Sales_Inc, data[indextr].Actual_Sales_Inc) > 0}]"> 
                                {{ $n(getDiffValues(data[indextr].Expected_Sales_Inc, data[indextr].Actual_Sales_Inc) , 'currency') }} 
                            </vs-td>
                            <vs-td :data="data[indextr].Booking_Receipt_Inc"> 
                                {{ $n(data[indextr].Booking_Receipt_Inc, 'currency') }}
                            </vs-td>
                            <vs-td :data="data[indextr].Booking_Receipt_Inc" :class="[{'alert': getDiffValues(data[indextr].Actual_Sales_Inc, data[indextr].Booking_Receipt_Inc) > 0}]">
                                {{ $n(getDiffValues(data[indextr].Actual_Sales_Inc, data[indextr].Booking_Receipt_Inc) , 'currency') }} 
                            </vs-td>
                            <vs-td class="options">
                                <vs-icon icon="expand_more" @click="toggleRow(indextr)"></vs-icon>
                            </vs-td> 
                        </vs-tr>
                        <vs-tr :key="`sub-${indextr}`" v-if="opened.includes(indextr)">
                            <vs-td colspan="13" class="noLineClamp">
                                <div class="bookingInfo">
                                    <div class="travel-info">
                                        <div class="item">
                                            <span class="subtitle">Booking Name</span>
                                            <p>{{  data[indextr].Booking_Name }}</p>
                                        </div>
                                        <div class="item">
                                            <span class="subtitle">Department Name</span>
                                            <p>{{  data[indextr].Booking_Department_Name }}</p>
                                        </div>
                                        <div class="item">
                                            <span class="subtitle">Fecha Viaje</span>
                                            <p>{{  data[indextr].Travel_Date | moment('timezone', 'Etc/GMT', 'll') }}</p>
                                        </div>
                                        <div class="item">
                                            <span class="subtitle">Cantidad de viajeros</span>
                                            <p>{{  data[indextr].Pax }}</p>
                                        </div>
                                    </div>
                                    <div class="service">
                                        <div class="notes">
                                            <div class="subtitle">Reservación - Estado</div>
                                            <p>{{ data[indextr].Booking_Status }}</p>
                                        </div>
                                        <div class="notes">
                                            <div class="subtitle">Agencia</div>
                                            <p>{{ data[indextr].Agent_Name }}</p>
                                            <span class="voucher">Código: {{ data[indextr].Agent_Code }}</span>
                                        </div>
                                        <div class="notes">
                                            <div class="subtitle">Agente</div>
                                            <p>{{ data[indextr].Booking_Consultant_Name }}</p>
                                            <span class="voucher">Código: {{ data[indextr].Booking_Consultant }}</span>
                                        </div>
                                    </div>
                                </div>
                            </vs-td>
                        </vs-tr>
                    </template>
                </template>
                <tr>
                    <td colspan=""></td>
                </tr>
            </vs-table>
        </div>
    </div>
</template>
<script>
export default {
    name: "TableBookingAnalysis",
    props: {
        items: { type: Array, default: () => [] },
    },
    data () {
        return {
            opened: [],
            selected:[],
            bookings: this.items,
        }
    },
    watch: {
        items(val){
            this.bookings = val
        }
    },
    methods: {
        toggleRow(id){
            const index   = this.opened.indexOf(id)
            this.opened = []
            if (index > -1) {
                this.opened.splice(index, 1)
                this.activeRow = -1
            } else {
                this.opened.push(id)
                this.activeRow = id
            }
        },
        getDiffValues(cost, paid){
            return cost - paid
        }
    }
}
</script>